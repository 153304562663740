{
  "name": "sharelock",
  "version": "1.0.5",
  "scripts": {
    "ng": "ng",
    "mock:server": "nodemon server/server.js --ext js,ts,json,html",
    "start:local": "ng serve --configuration=local-dev",
    "start:dev": "ng serve --configuration=dev",
    "start:uat": "ng serve --configuration=uat",
    "start:prod": "ng serve --configuration=prod",
    "start:mock": "nodemon server/server.js --ext js,ts,json,html & ng serve --configuration=mock",
    "install:ci": "npm i & npm install -D tailwindcss",
    "build:dev": "ng build --configuration=dev",
    "build:uat": "ng build --configuration=uat",
    "build:prod": "ng build --configuration=prod",
    "watch": "ng build --watch --configuration=local-dev",
    "test": "ng test --watch=false --browsers Chrome",
    "test:ci": "ng test --watch=false --browsers ChromiumNoSandbox",
    "serve:ssr:sharelock": "node dist/sharelock/server/server.mjs",
    "lint": "npx eslint \"src/**/*.{js,jsx,ts,tsx,html}\" --quiet --fix",
    "format": "npx prettier \"src/**/*.{js,jsx,ts,tsx,html,css,scss}\" --write",
    "prepare": "husky install",
    "updates:check": "npx npm-check-updates",
    "updates:interactive": "npx npm-check-updates -i"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.8",
    "@angular/cdk": "^17.3.2",
    "@angular/common": "^18.2.8",
    "@angular/compiler": "^18.2.8",
    "@angular/core": "^18.2.8",
    "@angular/forms": "^18.2.8",
    "@angular/material": "^17.3.2",
    "@angular/material-moment-adapter": "^17.3.2",
    "@angular/platform-browser": "^18.2.8",
    "@angular/platform-browser-dynamic": "^18.2.8",
    "@angular/platform-server": "^18.2.8",
    "@angular/router": "^18.2.8",
    "@angular/ssr": "^18.2.8",
    "@cospired/i18n-iso-languages": "^4.2.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "express": "^4.19.2",
    "i18n-iso-countries": "^7.13.0",
    "json-server": "^0.17.4",
    "ngx-mask": "^17.0.7",
    "ngx-translate-multi-http-loader": "^17.0.0",
    "npm-check-updates": "^16.14.18",
    "rxjs": "~7.8.1",
    "swiper": "^11.1.0",
    "tslib": "^2.6.2",
    "uuid": "^10.0.0",
    "vite": "^5.4.11",
    "zone.js": "~0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.8",
    "@angular-eslint/builder": "17.3.0",
    "@angular-eslint/eslint-plugin": "17.3.0",
    "@angular-eslint/eslint-plugin-template": "17.3.0",
    "@angular-eslint/schematics": "^18.3.1",
    "@angular-eslint/template-parser": "17.3.0",
    "@angular/cli": "^18.2.8",
    "@angular/compiler-cli": "^18.2.8",
    "@types/express": "^4.17.21",
    "@types/jasmine": "~5.1.4",
    "@types/node": "^20.12.2",
    "@types/uuid": "^10.0.0",
    "@typescript-eslint/eslint-plugin": "7.4.0",
    "@typescript-eslint/parser": "7.4.0",
    "autoprefixer": "^10.4.19",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.1.3",
    "husky": "^9.0.11",
    "jasmine-core": "~5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "lint-staged": "^15.2.2",
    "nodemon": "^3.1.4",
    "postcss": "^8.4.38",
    "prettier": "^3.2.5",
    "prettier-plugin-tailwindcss": "^0.5.13",
    "tailwindcss": "^3.4.3",
    "typescript": "<5.6.0"
  }
}
